import axios from 'axios';
import testData from './tempData.json';
const config = {
    headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + process.env.HUBSPOT_API_KEY,
        "Access-Control-Allow-Origin": "*"
    }
}

export const getHubspotData = async () => {
    const response = await axios.get('https://dashboard.api.thrice.agency/contactsAll', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Acccept': 'application/json'
        }                                            //just returning tempdata for now
    });
    if (response.status !== 200) {
        console.log("Error fetching data from Hubspot API");
        return;
    }else{
        console.log(response.data);
        return response.data;
    }

//    return testData;  //to just return tempdata for testing purposes


}