import React from "react";
import { ReactComponent as Arrow } from '../assets/arrowRight.svg';
import { ReactComponent as Close } from '../assets/Close.svg';
import { ReactComponent as Add } from '../assets/Add.svg';
import { useState } from "react";
import { useEffect } from "react";
import { all } from "axios";

const CampaignCard = (props) => {
    const [campaign, setCampaign] = useState(props.campaign);
    const [showAddStat, setShowAddStat] = useState(false);

    const allStats = ['prospects', 'accepted_invite', 'replied', 'first_call', 'second_call', 'sale', 'trial'];

    const getPercentage = (dividend, divisor) => {
        return divisor > 0 ? ((dividend / divisor) * 100).toFixed(2) : 0;
    }




    const removeCard = (e, stat) => {
        var tempCampaign = { ...campaign };
        if (tempCampaign.statsToShow.value.includes(stat) && stat !== "skip") {
            if (tempCampaign.statsToShow.value.filter(x => x !== "skip").length > 2) {
                tempCampaign.statsToShow.value.splice(tempCampaign.statsToShow.value.indexOf(stat), 1, "skip");
                setCampaign(tempCampaign);
            }
        }
    }


    const addCard = (stat) => {
        var tempCampaign = { ...campaign };
        if (!tempCampaign.statsToShow.value.includes(stat)) {
            tempCampaign.statsToShow.value.splice(allStats.indexOf(stat), 1, stat);
            setCampaign(tempCampaign);
        }
        if (tempCampaign.statsToShow.value.filter(x => x !== "skip").length == allStats.length) {
            setShowAddStat(false);

        }




    }

    const showAll = () => {

        var tempStats = campaign.statsToShow.value.filter(x => x !== "skip");
        return tempStats.map((stat, index) => {
            
            if (stat !== "skip") {
            return (<div className="stat-percentage">
                <div className="stat">
                    <div className="stat-close" onClick={(e) => removeCard(e, stat, campaign)}><Close className="close" /></div>
                    <div className="stat-title">{campaign[stat].title}</div>
                    <div className="stat-value">{campaign[stat].value}</div>
                </div>
                {index + 1 < tempStats.length && <div className="percentage">
                    <div className='percentage-title'>{getPercentage(campaign[tempStats[index + 1]].value, campaign[stat].value)}%</div>
                    <div className='arrow-container'><Arrow className="arrow" /></div>
                </div>}
            </div>)
            }
        })
    }

    return (<div className='campaign-container'>
        <div className="campaign-title">{campaign.name.value}</div>
        <div className="stats-container">
            <div className="add-stat">
                {allStats.map((stat, index) => {

                    if (!campaign.statsToShow.value.includes(stat)) {

                        return (<button key={index} onClick={(e) => addCard(e.target.value)} value={stat} className={"add-stat-card " + (showAddStat && " hidden-buttons")}>{campaign[stat].title}</button>)
                    }
                })}
                <div className="add-stat-button"><Add onClick={() => setShowAddStat(!showAddStat)} className={"add-icon " + (showAddStat && " rotate-45")} /></div>
                <div className="add-stat-title">Add Stat</div>
            </div>
            <div className="stats">
                {campaign && showAll()}
            </div>
        </div>
        <div className="spacer"></div>
    </div>);







}

export default CampaignCard;