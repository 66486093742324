import '../../styles/home.css';
import { getHubspotData } from '../../utils/hubspotApi';
import { useEffect } from 'react';
import React from 'react';
import { useState } from 'react';
import BarChart from '../../components/barChart';
import CampaignObj from '../../components/campaignObj';


function Home() {
  const [data, setData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [stats, setStats] = useState({
    "acceptedInviteAvg": 0,
    "repliedAvg": 0,
    "firstCallAvg": 0,
    "secondCallAvg": 0,
    "saleAvg": 0,
    "trialAvg": 0
  });
  const [displayStats, setDisplayStats] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [displayCampaigns, setDisplayCampaigns] = useState([]);
  const [disabled, setDisabled] = useState(false);


  useEffect(() => {
    getHubspotData().then(res => {
      // setData(res.results); 
      setData(res);
      parseData(res);
    });


  }, []);

  useEffect(() => {
    calculateSelectedStats();
    if(displayCampaigns.length === 4){
      setDisabled(true);
    }
  }, [displayCampaigns]);

  useEffect(() => {
    calculateStats();
  }, [campaigns]);




  const parseData = (passedData) => {
    var campaigns = [];
    // var stats = {
    //   "prospects": 0,
    //   "accepted_invite": 0,
    //   "replied": 0,
    //   "first_call": 0,
    //   "second_call": 0,
    //   "sale": 0,
    //   "trial": 0
    // }
    for (var i = 0; i < passedData.length; i++) {
      var contact = passedData[i];
      if (!campaigns.find(campaign => campaign.name.value === contact.properties["lh_campaign_name"]) && contact.properties["lh_campaign_name"] !== null) {

        campaigns.push({
          "statsToShow": {
            "value": ["prospects", "accepted_invite", "replied", "first_call", "second_call", "trial", "sale"],
            "order": 0
          },
          "name": {
            "title": "Name",
            "value": contact.properties["lh_campaign_name"],
            "order": 1
          },
          "prospects": {
            "title": "Linked Helper Prospect",
            "value": 0,
            "order": 2
          },
          "accepted_invite": {
            "title": "Accepted Invite",
            "value": 0,
            "order": 3
          },
          "replied": {
            "title": "Replied",
            "value": 0,
            "order": 4
          },
          "first_call": {
            "title": "First Call",
            "value": 0,
            "order": 5
          },
          "second_call": {
            "title": "Second Call",
            "value": 0,
            "order": 6
          },
          "sale": {
            "title": "Sale",
            "value": 0,
            "order": 7
          },
          "trial":{
            "title": "Trial",
            "value": 0,
            "order": 8
          }
        });
      }


      campaigns.find(campaign => {
        if (campaign.name.value === contact.properties["lh_campaign_name"]) {
          switch (contact.properties["hs_lead_status"]) {
            case "ACCEPTED_INVITE":
              campaign["accepted_invite"].value += 1;
              break;
            case "REPLIED":
              campaign["accepted_invite"].value += 1;
              campaign["replied"].value += 1;
              break;
            case "FIRST_CALL":
              campaign["accepted_invite"].value += 1;
              campaign["replied"].value += 1;
              campaign["first_call"].value += 1;
              break;
            case "SECOND_CALL":
              campaign["accepted_invite"].value += 1;
              campaign["replied"].value += 1;
              campaign["first_call"].value += 1;
              campaign["second_call"].value += 1;
              break;
            case "SALE":
              campaign["accepted_invite"].value += 1;
              campaign["replied"].value += 1;
              campaign["first_call"].value += 1;
              campaign["second_call"].value += 1;
              campaign["sale"].value += 1;
              break;
            case "TRIAL":
              campaign["accepted_invite"].value += 1;
              campaign["replied"].value += 1;
              campaign["first_call"].value += 1;
              campaign["second_call"].value += 1;
              campaign["sale"].value += 1;
              campaign["trial"].value += 1;
              break;
            default:
              break;
          }
          campaign["prospects"].value += 1;
        }
      });
    }
    setCampaigns(campaigns);

  }

  const calculateAverage = (dividend, divisor, campaign) => {
    return (divisor > 0 ? parseFloat(((dividend / campaign.length) / (divisor / campaign.length)) * 100).toFixed(2) : 0);
  }


  const calculateStats = () => {
    var tempTotalStats = {
      "prospects": 0,
      "accepted_invite": 0,
      "replied": 0,
      "first_call": 0,
      "second_call": 0,
      "sale": 0,
      "trial": 0
    }

    campaigns.map(campaign => {
      tempTotalStats["prospects"] += campaign.prospects.value;
      tempTotalStats["accepted_invite"] += campaign.accepted_invite.value;
      tempTotalStats["replied"] += campaign.replied.value;
      tempTotalStats["first_call"] += campaign.first_call.value;
      tempTotalStats["second_call"] += campaign.second_call.value;
      tempTotalStats["sale"] += campaign.sale.value;
      tempTotalStats["trial"] += campaign.trial.value;

    });


    var tempStats = {
      "acceptedInviteAvg": 0,
      "repliedAvg": 0,
      "firstCallAvg": 0,
      "secondCallAvg": 0,
      "saleAvg": 0,
      "trialAvg": 0
    };
    tempStats["acceptedInviteAvg"] = (calculateAverage(tempTotalStats["accepted_invite"], tempTotalStats["prospects"], campaigns) + "%");
    tempStats["repliedAvg"] = (calculateAverage(tempTotalStats["replied"], tempTotalStats["accepted_invite"], campaigns) + "%");
    tempStats["firstCallAvg"] = (calculateAverage(tempTotalStats["first_call"], tempTotalStats["replied"], campaigns) + "%");
    tempStats["secondCallAvg"] = (calculateAverage(tempTotalStats["second_call"], tempTotalStats["first_call"], campaigns) + "%");
    tempStats["saleAvg"] = (calculateAverage(tempTotalStats["sale"], tempTotalStats["second_call"], campaigns) + "%");
    tempStats["trialAvg"] = (calculateAverage(tempTotalStats["trial"], tempTotalStats["sale"], campaigns) + "%");

    setStats(tempStats);
  }

  const calculateSelectedStats = () => {
    var tempSelectedStats = {
      "prospects": 0,
      "accepted_invite": 0,
      "replied": 0,
      "first_call": 0,
      "second_call": 0,
      "sale": 0,
      "trial": 0
    }

    displayCampaigns.map(campaign => {
      tempSelectedStats["prospects"] += campaign.prospects.value;
      tempSelectedStats["accepted_invite"] += campaign.accepted_invite.value;
      tempSelectedStats["replied"] += campaign.replied.value;
      tempSelectedStats["first_call"] += campaign.first_call.value;
      tempSelectedStats["second_call"] += campaign.second_call.value;
      tempSelectedStats["sale"] += campaign.sale.value;
      tempSelectedStats["trial"] += campaign.trial.value;

    });

    var tempStats = {
      "acceptedInviteAvg": 0,
      "repliedAvg": 0,
      "firstCallAvg": 0,
      "secondCallAvg": 0,
      "saleAvg": 0,
      "trialAvg": 0
    };
    
    tempStats["acceptedInviteAvg"] = (calculateAverage(tempSelectedStats["accepted_invite"], tempSelectedStats["prospects"], displayCampaigns) + "%");
    tempStats["repliedAvg"] = (calculateAverage(tempSelectedStats["replied"], tempSelectedStats["accepted_invite"], displayCampaigns) + "%");
    tempStats["firstCallAvg"] = (calculateAverage(tempSelectedStats["first_call"], tempSelectedStats["replied"], displayCampaigns) + "%");
    tempStats["secondCallAvg"] = (calculateAverage(tempSelectedStats["second_call"], tempSelectedStats["first_call"], displayCampaigns) + "%");
    tempStats["saleAvg"] = (calculateAverage(tempSelectedStats["sale"], tempSelectedStats["second_call"], displayCampaigns) + "%"); 
    tempStats["trialAvg"] = (calculateAverage(tempSelectedStats["trial"], tempSelectedStats["sale"], displayCampaigns) + "%");


    setDisplayStats(tempStats);
    

  }


  

  const changeSelection = (e, campaign) => {
   
    if(displayCampaigns.length >= 4 && e.target.checked){
      e.target.checked = false;
      return;
    }
    if (e.target.checked && displayCampaigns.length < 4) {

      setDisplayCampaigns([...displayCampaigns, campaign]);
    } else {
      setDisplayCampaigns(displayCampaigns.filter(camp => camp.name.value !== campaign.name.value));
    }

  }                       


  return (
    <div className="App">
    <div className="select-and-stats">
      {campaigns.length == 0 && <div>Loading...</div> }
      {campaigns.length > 0 && (<><div className="stats-parent-container">
      <div className="campaign-selector">
        <div className="campaign-selector-title">Campaign Selector</div>
        <div className="campaign-selector-subtitle">Select Campaigns to Compare (max 4)</div>
        <div className="campaign-selector-checkbox">
          {campaigns.length > 0 && campaigns.map((campaign, index) => {
            return (<div className="checkbox-container"><input  type="checkbox" key={index} value={campaign.name.value} onChange={(e) => changeSelection(e, campaign)} /><label for={campaign.name.value}>{campaign.name.value}</label><br /></div>)
          })}
        </div>
      </div>
      
      <div className="avg-stats">
        <div className="avg-stat-title"><h1>Average Selected Stats</h1></div>
        <div className="avg-stat">
          <div className="avg-stat-title">Accepted Invite: </div>
          <div className="avg-stat-value">{displayStats.acceptedInviteAvg}</div>
        </div>
        <div className="avg-stat">
          <div className="avg-stat-title">Replied: </div>
          <div className="avg-stat-value">{displayStats.repliedAvg}</div>
        </div>
        <div className="avg-stat">
          <div className="avg-stat-title">First Call: </div>
          <div className="avg-stat-value">{displayStats.firstCallAvg}</div>
        </div>
        <div className="avg-stat">
          <div className="avg-stat-title">Second Call: </div>
          <div className="avg-stat-value">{displayStats.secondCallAvg}</div>
        </div>
        <div className="avg-stat">
          <div className="avg-stat-title">Sale: </div>
          <div className="avg-stat-value">{displayStats.saleAvg}</div>
        </div>
        <div className="avg-stat">
          <div className="avg-stat-title">Trial: </div>
          <div className="avg-stat-value">{displayStats.trialAvg}</div>
        </div>

      </div>
      <div className="avg-stats">
        <div className="avg-stat-title"><h1>Average Total Stats</h1></div>
        <div className="avg-stat">
          <div className="avg-stat-title">Accepted Invite: </div>
          <div className="avg-stat-value">{stats.acceptedInviteAvg}</div>
        </div>
        <div className="avg-stat">
          <div className="avg-stat-title">Replied: </div>
          <div className="avg-stat-value">{stats.repliedAvg}</div>
        </div>
        <div className="avg-stat">
          <div className="avg-stat-title">First Call: </div>
          <div className="avg-stat-value">{stats.firstCallAvg}</div>
        </div>
        <div className="avg-stat">
          <div className="avg-stat-title">Second Call: </div>
          <div className="avg-stat-value">{stats.secondCallAvg}</div>
        </div>
        <div className="avg-stat">
          <div className="avg-stat-title">Sale: </div>
          <div className="avg-stat-value">{stats.saleAvg}</div>
        </div>
        <div className="avg-stat">
          <div className="avg-stat-title">Trial: </div>
          <div className="avg-stat-value">{stats.trialAvg}</div>
        </div>
        

      </div>
      </div></>)}

      
      {displayCampaigns.length > 0 && <CampaignObj campaigns={displayCampaigns} />}

    </div>
    <div className="bar-chart">
      {/* {displayCampaigns.length > 0 && <BarChart data={displayCampaigns} />} */}
      </div>
    </div>
  );
}

export default Home;
