import React from "react";
import { Chart } from "react-google-charts";



const BarChart = ({ data }) => {

    const tempData = [
        ["Lead Status"],
        ["Accepted"],
        ["Replied"],
        ["First Call"],
        ["Second Call"],
        ["Sale"],
        ["Trial"]
    ]

    data.forEach((campaign, index) => {
        if(index < 4){
        tempData[0].push(campaign.name.value);
        tempData[1].push(campaign.accepted_invite.value);
        tempData[2].push(campaign.replied.value);
        tempData[3].push(campaign.first_call.value);
        tempData[4].push(campaign.second_call.value);
        tempData[5].push(campaign.sale.value);
        tempData[6].push(campaign.trial.value);
        }
    });


    const options = {
        chart: {
            title: "Total Leads by Campaign",
            subtitle: "Prospect, Accepted, Replied, First Call, Sale",
          },
          bars: 'horizontal',
          colors: ['#18CFF0', '#436970','#3E9FB0', '#F07A18']
    };

    return (
        <Chart
            chartType="Bar"
            width={"100%"}
            height={"60rem"}
            data={tempData}
            options={options}
        />
    );
}

export default BarChart;