import React from "react";
import { ReactComponent as Arrow } from '../assets/arrowRight.svg';
import { ReactComponent as Close } from '../assets/Close.svg';
import { useState } from "react";
import { useEffect } from "react";
import CampaignCard from "./campaignCard";






const CampaignObj = ({ campaigns }) => {
  
    const [campaign, setCampaign] = useState(campaigns);

    useEffect(() => {
        setCampaign(campaigns); 
    });

    return (<div>
    
    {campaign.length > 0 && campaign.map((campaign, index1) => {
        return (<CampaignCard key={index1} campaign={campaign}/>);})}
    </div>);
}

export default CampaignObj;